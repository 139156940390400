<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template  v-slot:headerTitle>
            <h4 class="card-title">{{ $t('pest_management.disease') + ' ' + $t('globalTrans.entry') }}</h4>
         </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-overlay :show="loadingState">
                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-row>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="insect.org_id"
                                            :options="orgList"
                                            id="org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Crop Type" vid="crop_type_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('VirtualMuseum.configuration.crop_type')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="insect.crop_type_id"
                                            :options="cropTypeList"
                                            id="crop_type_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Crop" vid="crop_id" rules="required|min_value:1">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="org_id"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.crop')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                            plain
                                            v-model="insect.crop_id"
                                            :options="cropList"
                                            id="crop_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Insect Name (En)" vid="name" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="name"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.insect_name_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="name"
                                            v-model="insect.name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Insect Name (Bn)" vid="name_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="designation"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.insect_name_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                            id="designation"
                                            v-model="insect.name_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-input>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Description (En)" vid="description" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="name"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.description_en')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                            id="museum_description"
                                            v-model="insect.description"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col xl="6" lg="6" sm="12">
                                    <ValidationProvider name="Description (Bn)" vid="description_bn" rules="required">
                                        <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="name"
                                            slot-scope="{ valid, errors }"
                                        >
                                            <template v-slot:label>
                                            {{$t('pest_management.description_bn')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-textarea
                                            id="museum_description_bn"
                                            v-model="insect.description_bn"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            ></b-form-textarea>
                                            <div class="invalid-feedback">
                                            {{ errors[0] }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <!-- Life cycle start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.life_cycle')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.preview')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:40%; height: 50px !important">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-textarea
                                                id="life_cycle_desc"
                                                v-model="insect.life_cycle_desc"
                                                cols="40"
                                                rows="3"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-td>
                                        <b-td style="width:40%">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="life_cycle_photo"
                                                v-model="insect.life_cycle_photo"
                                                @change="lifeCycleFileChange"
                                                ></b-form-file>
                                            </b-form-group>
                                        </b-td>
                                        <b-td style="width:20%">
                                            <b-img v-if="life_cycle_img_pr" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="life_cycle_img_pr" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" alt="profile-pic" />
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Life cycle end -->

                             <!-- Destructive start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.destructive_stage')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.preview')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:40%; height: 50px !important">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-textarea
                                                id="destructive_desc"
                                                v-model="insect.destructive_desc"
                                                cols="40"
                                                rows="3"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-td>
                                        <b-td style="width:40%">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="destructive_photo"
                                                @change="destructiveFileChange"
                                                ></b-form-file>
                                            </b-form-group>
                                        </b-td>
                                         <b-td style="width:20%">
                                            <b-img v-if="destructive_photo_pr" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="destructive_photo_pr" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                     </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Destructive End -->

                            <!-- Attacking parts start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.attacking_parts')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.preview')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:40%; height: 50px !important">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-textarea
                                                id="attacking_parts_desc"
                                                v-model="insect.attacking_parts_desc"
                                                cols="40"
                                                rows="3"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-td>
                                        <b-td style="width:40%">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="attacking_part_photo"
                                                @change="attackingPartsFileChange"
                                                ></b-form-file>
                                            </b-form-group>
                                        </b-td>
                                        <b-td style="width:20%">
                                            <b-img v-if="attacking_parts_photo_pr" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="attacking_parts_photo_pr" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                     </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Attacking parts end -->

                            <!-- Effect on start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.effect_on_plant')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.preview')}}</b-th>
                                    </b-tr>
                                </b-tbody>
                                <b-tr>
                                        <b-td style="width:40%; height: 50px !important">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-textarea
                                                id="effect_plant_desc"
                                                v-model="insect.effect_plant_desc"
                                                cols="40"
                                                rows="3"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-td>
                                         <b-td style="width:40%">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="effect_plant_photo"
                                                @change="effectPlantFileChange"
                                                ></b-form-file>
                                            </b-form-group>
                                        </b-td>
                                        <b-td style="width:20%">
                                            <b-img v-if="effect_plant_photo_pr" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="effect_plant_photo_pr" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                </b-tr>
                            </b-table-simple>
                            <!-- Effect on end -->

                            <!-- Symptom start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.symptom')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.preview')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:40%; height: 50px !important">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-textarea
                                                id="symptom_desc"
                                                v-model="insect.symptom_desc"
                                                cols="40"
                                                rows="3"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-td>
                                         <b-td style="width:40%">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="symptom_photo"
                                                @change="symptomFileChange"
                                                ></b-form-file>
                                            </b-form-group>
                                        </b-td>
                                        <b-td style="width:20%">
                                            <b-img v-if="symptom_photo_pr" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="symptom_photo_pr" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!-- Symptom end -->

                            <!-- Management start -->
                            <div style="background-color: #005B5B; padding: 5px">
                                <h4 class="text-white text-center"> {{$t('pest_management.management')}}
                                </h4>
                            </div>
                            <b-table-simple striped bordered small hover>
                                <b-tbody>
                                    <b-tr>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.description')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.upload_file')}}</b-th>
                                        <b-th style="padding-left: 17px !important;">{{$t('pest_management.preview')}}</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-td style="width:40%; height: 50px !important">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-textarea
                                                id="management_desc"
                                                v-model="insect.management_desc"
                                                cols="40"
                                                rows="3"
                                                ></b-form-textarea>
                                            </b-form-group>
                                        </b-td>
                                         <b-td style="width:40%">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="1"
                                            >
                                                <b-form-file
                                                accept="image/*"
                                                id="management_photo"
                                                @change="managementFileChange"
                                                ></b-form-file>
                                            </b-form-group>
                                        </b-td>
                                        <b-td style="width:20%">
                                            <b-img v-if="management_photo_pr" style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="management_photo_pr" />
                                            <b-img v-else style="margin:10px 0px 10px 10px" class="height-100 width-100" fluid :src="defaultImage" />
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>

                            <div class="row">
                                <div class="col-sm-3"></div>
                                <div class="col text-right">
                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                    &nbsp;
                                    <b-button variant="danger" class="mr-1">
                                        <router-link :class="'text-light'" :to="{ path: '/virtual-museum-service/pest-management/manage-insect'}" size="sm">{{ $t('globalTrans.cancel') }}
                                        </router-link>
                                    </b-button>
                                </div>
                            </div>
                        </b-form>
                    </b-overlay>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { insectManageStoreApi, insectManageDetailsApi } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.id) {
      const tmp = this.getDetails()
      this.insect = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
        loadingState: false,
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        cropList: [],
        insect: {
            org_id: 0,
            name: '',
            name_bn: '',
            description: '',
            description_bn: '',
            crop_id: '0',
            crop_type_id: '0',
            life_cycle_desc: '',
            life_cycle_photo: [],
            destructive_desc: '',
            destructive_photo: [],
            attacking_parts_desc: '',
            attacking_parts_photo: [],
            effect_plant_desc: '',
            effect_plant_photo: [],
            symptom_desc: '',
            symptom_photo: [],
            management_desc: '',
            management_photo: []
        },
        life_cycle_img_pr: null,
        destructive_photo_pr: null,
        attacking_parts_photo_pr: null,
        effect_plant_photo_pr: null,
        symptom_photo_pr: null,
        management_photo_pr: null,
        life_cycle_attachment: [],
        destructive_attachment: [],
        attacking_parts_attachment: [],
        effect_plant_attachment: [],
        symptom_attachment: [],
        management_attachment: [],
        defaultImage: require('../../../../../assets/images/no-image.png')

    }
  },
  computed: {
      orgList: function () {
          const objectData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn }
              } else {
                  return { value: obj.value, text: obj.text_en }
              }
          })
      },
      cropTypeList: function () {
            const cropTypeData = this.$store.state.VirtualMuseum.commonObj.cropTypeList.filter(item => item.status === 1)
            return cropTypeData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        }
  },
  watch: {
    'insect.crop_type_id': function (newVal, oldVal) {
        this.cropList = this.getCropList(newVal)
    }
  },
  methods: {
    async getDetails () {
      this.loadingState = true
      const result = await RestApi.getData(virtualMuseumServiceBaseUrl, insectManageDetailsApi + '/' + this.$route.query.id)
      if (result.success) {
            this.insect = result.data
            const org = this.$store.state.commonObj.organizationProfileList.find(org => org.value === this.insect.org_id)
            const cropType = this.$store.state.VirtualMuseum.commonObj.cropTypeList.find(type => type.value === this.insect.crop_type_id)
            const crop = this.$store.state.VirtualMuseum.commonObj.cropList.find(crop => crop.value === this.insect.crop_id)
            this.organization_name = this.$i18n.locale === 'bn' ? org.text_bn : org.text_en
            this.crop_type = this.$i18n.locale === 'bn' ? cropType.text_bn : cropType.text_en
            this.crop_name = this.$i18n.locale === 'bn' ? crop.text_bn : crop.text_en
        }
        this.loadingState = false
     },
     getCropList () {
        const cropData = this.$store.state.VirtualMuseum.commonObj.cropList.filter(item => item.type_id === this.insect.crop_type_id)
        return cropData
    },
    lifeCycleFileChange (e) {
      this.life_cycle_attachment = e.target.files[0]
      this.life_cycle_img_pr = URL.createObjectURL(this.life_cycle_attachment)
    },
    destructiveFileChange (e) {
      this.destructive_attachment = e.target.files[0]
      this.destructive_photo_pr = URL.createObjectURL(this.destructive_attachment)
    },
    attackingPartsFileChange (e) {
      this.attacking_parts_attachment = e.target.files[0]
      this.attacking_parts_photo_pr = URL.createObjectURL(this.attacking_parts_attachment)
    },
    effectPlantFileChange (e) {
      this.effect_plant_attachment = e.target.files[0]
      this.effect_plant_photo_pr = URL.createObjectURL(this.effect_plant_attachment)
    },
    symptomFileChange (e) {
      this.symptom_attachment = e.target.files[0]
      this.symptom_photo_pr = URL.createObjectURL(this.symptom_attachment)
    },
    managementFileChange (e) {
      this.management_attachment = e.target.files[0]
      this.management_photo_pr = URL.createObjectURL(this.management_attachment)
    },
    async saveData () {
      this.loadingState = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }
    //   const config = {
    //     headers: { 'content-type': 'multipart/form-data' }
    //   }
      var formData = new FormData()
      Object.keys(this.insect).map(key => {
          if (key === 'life_cycle_photo') {
            formData.append('life_cycle_photo', this.life_cycle_attachment)
          } else if (key === 'destructive_photo') {
            formData.append('destructive_photo', this.destructive_attachment)
          } else if (key === 'attacking_parts_photo') {
            formData.append('attacking_parts_photo', this.attacking_parts_attachment)
          } else {
            formData.append(key, this.insect[key])
          }
      })
    //   result = await RestApi.postData(virtualMuseumServiceBaseUrl, insectManageStoreApi, formData, config)
      result = await RestApi.postData(virtualMuseumServiceBaseUrl, insectManageStoreApi, this.insect)
      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
            this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$router.push('manage-insect')
      } else {
            this.$refs.form.setErrors(result.errors)
      }
      this.loadingState = false
    }
  }
}
</script>
