<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <b-row>
                        <b-col lg="8 offset-lg-2" sm="12">
                          <ValidationProvider name="Tour operator" vid="tour_operator_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="tour_operator_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('tour_management.tour_operator') }} <span class="text-danger">*</span>
                                </template>
                            <b-form-select
                                plain
                                v-model="approve.tour_operator_id"
                                :options="tourOperterList"
                                id="tour_operator_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Note" vid="note" rules="required|max:200">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="note"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{ $t('tour_management.note') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                    plain
                                    v-model="approve.note"
                                    id="note"
                                    placeholder="Maximum 200 word.."
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                </b-form-textarea>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <div class="row">
                        <div class="col-8 offset-2" sm="12">
                            <div class="text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-approve')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { virtualMuseumServiceBaseUrl } from '@/config/api_config'
import { phyVisitAdminFeedbackApi } from '../../api/routes'

export default {
  props: ['id', 'requestid', 'orgid'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.$t('globalTrans.save'),
      approve: {
        visit_request_id: this.id,
        tour_operator_id: 0,
        status: 3,
        note: ''
      }
      // officerLoading: false,
      // tourOptList: []
    }
  },
  computed: {
    designationList: function () {
        return this.$store.state.commonObj.designationList.filter(item => item.status === 0 && item.org_id === 2)
    },
    tourOperterList: function () {
          const objectData = this.$store.state.VirtualMuseum.commonObj.tourOperator.filter(sinOpt => sinOpt.org_id === this.orgid)
          return objectData.map((obj, key) => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.name }
              } else {
                  return { value: obj.value, text: obj.name }
              }
          })
        }
  },
  watch: {
    // 'forward.designation_id': function (newValue) {
    //     this.getOfficerList(newValue)
    // }
  },
  methods: {
    getRegionInfoData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }

        result = await RestApi.postData(virtualMuseumServiceBaseUrl, phyVisitAdminFeedbackApi, this.approve)

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)
        this.loading = false

        if (result.success) {
          // const notification = result.notification
          // this.$socket.emit('send-notification', notification)
            this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })

            this.$bvModal.hide('modal-approve')
            this.$bvModal.hide('modal-5')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
